import {createSlice} from '@reduxjs/toolkit';

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    htmlContent: '',
  },
  reducers: {
    setPaymentHtml: (state, action) => {
      state.htmlContent = action.payload;
    },
  },
});

export const { setPaymentHtml } = paymentSlice.actions;

export default paymentSlice.reducer;