import "./RenewSubscriptionPage.css";
import { Alert, Badge, CircularProgress, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import NavigationIcon from "../navigationIcon.svg";
import Box from "@mui/joy/Box";
import { Typography } from "@mui/joy";
import RadioGroup from "@mui/joy/RadioGroup";
import List from "@mui/joy/List";
import React, { useEffect, useState } from "react";
import ListDivider from "@mui/joy/ListDivider";
import ListItem from "@mui/joy/ListItem";
import Radio from "@mui/joy/Radio";
import { configuration } from "../state/configuration";
import { useDispatch } from "react-redux";
import { setPaymentHtml } from "../state/payment/PaymentSlice"; // Import the CSS file

type DurationType = "1个月" | "3个月" | "6个月" | "12个月";

type DurationWithBadge = {
  duration: DurationType;
  badgeContent: string | null;
};

type RenewSubscriptionPageProps = {
  userEmail: string;
};

function RenewSubscriptionPage({ userEmail }: RenewSubscriptionPageProps) {
  const apiUrl = configuration.SUB_API_URL;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Inside the RenewSubscriptionPage component
  const location = useLocation();
  const email = location.state
    ? (location.state as { email: string }).email
    : undefined;

  const [paymentType, setPaymentType] = useState<PaymentType>("支付宝支付");
  // Define the state variables for selectedPlan and selectedDuration
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>();
  const [selectedDuration, setSelectedDuration] = useState<
    string | undefined
  >();

  // Define the functions to update the state variables
  const onClickSelectPlan = (plan: string) => {
    setSelectedPlan(plan);
  };

  const onClickSelectDuration = (duration: string) => {
    setSelectedDuration(duration);
  };

  const durationsWithBadges: DurationWithBadge[] = [
    { duration: "1个月", badgeContent: "1元/天" },
    { duration: "3个月", badgeContent: "25元/月" },
    { duration: "6个月", badgeContent: "20元/月" },
    { duration: "12个月", badgeContent: "15元/月" },
  ];

  type PaymentType = "支付宝支付" | "微信支付";

  const paymentTypeMapping: Record<PaymentType, string> = {
    支付宝支付: "alipay",
    微信支付: "wxpay",
  };
  const [price, setPrice] = useState(0); // Add a state variable for the price
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedDuration("12个月");
    setSelectedPlan("个人套餐");
  }, [dispatch]);

  useEffect(() => {
    if (selectedPlan === "定制专线") {
      setPrice(10);
    } else if (selectedPlan === "个人套餐") {
      switch (selectedDuration) {
        case "1个月":
          setPrice(30);
          break;
        case "3个月":
          setPrice(75);
          break;
        case "6个月":
          setPrice(120);
          break;
        case "12个月":
          setPrice(180);
          break;
        default:
          setPrice(0);
      }
    } else {
      setPrice(0);
    }
  }, [selectedPlan, selectedDuration]);

  // Step 1: Create a state variable for the error message
  const [emailError, setEmailError] = useState("");

  function startPayment() {
    setIsLoading(true); // Show the progress indicator
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
        mail: email,
        // accountPassword: password,
        payAmount: price, // replace with the actual amount
        // payAmount: 0.1, // replace with the actual amount
        subscriptionPlan: selectedPlan,
        subscriptionDuration: selectedDuration,
        // "wxpay" or "alipay"
        paymentType: paymentTypeMapping[paymentType], // Use the paymentType state variable to get the English name
        // testFlag: true,
      }),
    };
    fetch(`${apiUrl}/api/payments/renew`, requestOptions)
      .then((response) => {
        setIsLoading(false); // Hide the progress indicator
        if (response.ok) {
          return response.text(); // Get the response as text
        } else if (response.status === 409) {
          //if the response status is 409,display Alert on screen with the following message: This account has been registered. Alert dispears in 3 second automatically.
          // Step 2: Set the alert visibility to true when the status is 409
          setShowAlert(true);
          // Step 3: Set the alert visibility to false after 3 seconds
          setTimeout(() => setShowAlert(false), 3000);
          throw new Error("This account has been registered");
        } else {
          throw new Error("Response status is not 200");
        }
      })
      .then((data) => {
        dispatch(setPaymentHtml(data));
        navigate("/payment");
      })
      .catch((error) => {
        setIsLoading(false); // Hide the progress indicator
        // Display a error
        console.error("Error:", error);
      });
  }

  function GradientCircularProgress() {
    return (
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#e01cd5" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    );
  }

  return (
    <div className="home w-full bg-gray-900 max-w-md mx-auto min-h-screen h-auto">
      <div className="text-white p-3 w-full max-w-md min-h-screen">
        {isLoading && (
          <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50 z-50">
            <GradientCircularProgress />
          </div>
        )}
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
          {showAlert && (
            <Alert
              variant="filled"
              severity="error"
              onClose={() => setShowAlert(false)}
            >
              This account has been registered.
            </Alert>
          )}
        </div>
        <header className="font-bold text-center mb-6">
          <div className="text-3xl mb-4">101加速器</div>
          <div className="flex items-center">
            <div>选择续费套餐</div>
            <Button
              component={RouterLink}
              className="text-sm text-color-custom" // Remove 'ml-auto'
              to="/order"
              style={{
                marginLeft: "auto",
              }}
            >
              返回查询订单
              <img
                src={NavigationIcon}
                alt="navigation"
                className="inline-block ml-2"
                style={{ width: "1.8em", height: "1.8em" }}
              />
            </Button>
            <br />
          </div>
        </header>

        <div className="flex justify-between mb-4 px-4">
          <div
            onClick={() => onClickSelectPlan("个人套餐")}
            className={`bg-gray-800 p-3 rounded-lg text-sm  mr-5 ${
              selectedPlan === "个人套餐" ? "selected" : "un-selected"
            }`}
          >
            <div className="text-xl font-bold mb-4">个人无限套餐</div>
            <div className="flex items-center ">
              <i className="fas fa-check-circle text-green-500 mr-2"></i>
              <span>包月专线,不限流量</span>
            </div>
            <div className="flex items-center">
              <i className="fas fa-check-circle text-green-500 mr-2"></i>
              <span>4K秒开TG看片也不卡</span>
            </div>
            <div className="flex items-center">
              <i className="fas fa-check-circle text-green-500 mr-2"></i>
              <span>流媒体全解锁</span>
            </div>
            <div className="flex items-center">
              <i className="fas fa-check-circle text-green-500 mr-2"></i>
              <span>支持ChatGPT</span>
            </div>
            <div className="flex items-center">
              <i className="fas fa-check-circle text-green-500 mr-2"></i>
              <span>赠送永久免费节点</span>
            </div>
            <div className="flex items-center">
              <i className="fas fa-check-circle text-green-500 mr-2"></i>
              <span>支持3台设备使用</span>
            </div>
          </div>
          <div
            onClick={() => onClickSelectPlan("定制专线")}
            className={`bg-gray-800 p-4 rounded-lg text-sm ${
              selectedPlan === "定制专线" ? "selected" : "un-selected"
            }`}
          >
            <div className="text-xl font-bold mb-4">定制独享专线</div>
            <div className="flex items-center">
              <i className="fas fa-check-circle text-green-500 mr-2"></i>
              <span>独享带宽流量无限</span>
            </div>
            <div className="flex items-center">
              <i className="fas fa-check-circle text-green-500 mr-2"></i>
              <span>定向TK，AZ平台加速</span>
            </div>
            <div className="flex items-center">
              <i className="fas fa-check-circle text-green-500 mr-2"></i>
              <span>高速上行支持直播</span>
            </div>
            <div className="flex items-center">
              <i className="fas fa-check-circle text-green-500 mr-2"></i>
              <span>纯净IP防关联</span>
            </div>
            <div className="flex items-center">
              <i className="fas fa-check-circle text-green-500 mr-2"></i>
              <span>赠送永久免费节点</span>
            </div>
            <div className="flex items-center">
              <i className="fas fa-check-circle text-green-500 mr-2"></i>
              <span>不限使用设备数</span>
            </div>
          </div>
        </div>
        <div className="mb-2 pt-2">
          {selectedPlan === "定制专线" ? (
            <div className="text-center mb-4" style={{ height: "40px" }}>
              定制专线用户支付定金后，会有客服与您联系确认线路需求后专人搭建交付。
            </div>
          ) : (
            <div
              className="flex justify-between mb-4"
              style={{ height: "40px" }}
            >
              {durationsWithBadges.map(({ duration, badgeContent }) =>
                badgeContent ? (
                  <Badge
                    key={duration}
                    badgeContent={badgeContent}
                    color="primary"
                    className="mr-4" // Add margin to the right
                  >
                    <div
                      onClick={() => onClickSelectDuration(duration)}
                      className={`bg-gray-800 p-2 rounded-lg text-center ${
                        selectedDuration === duration
                          ? "selected"
                          : "un-selected"
                      }`}
                    >
                      {duration}
                    </div>
                  </Badge>
                ) : (
                  <div
                    key={duration}
                    onClick={() => onClickSelectDuration(duration)}
                    className={`bg-gray-800 p-2 rounded-lg text-center ${
                      selectedDuration === duration ? "selected" : "un-selected"
                    }`}
                  >
                    {duration}
                  </div>
                )
              )}
            </div>
          )}
          <div className="flex justify-between items-center bg-gray-800 p-1 rounded-lg mb-4">
            <TextField
              type="text"
              placeholder="接收邮箱（订阅查询使用一致）"
              className="bg-gray-800 text-white outline-none w-full"
              value={email}
              // Step 3: Pass the error message as the helperText prop
              helperText={emailError}
              // Step 4: Set the error prop based on whether there is an error message
              error={!!emailError}
              inputProps={{
                style: {
                  color: "white",
                },
              }}
              disabled={true}
            />
          </div>
          {/*<div className="bg-gray-800 p-1 rounded-lg mb-1">*/}
          {/*  <TextField*/}
          {/*    type="password"*/}
          {/*    placeholder="新订阅查询密码(输入就会更新)"*/}
          {/*    className="bg-gray-800 text-white outline-none w-full"*/}
          {/*    value={password}*/}
          {/*    onChange={(e) => {*/}
          {/*      onChangePassword(e.target.value);*/}
          {/*    }}*/}
          {/*    inputProps={{ style: { color: "white" } }}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <Box>
          <Typography
            id="payment-channel-label"
            level="title-md"
            textColor={"white"}
            fontWeight="xl"
            mb={1} // Add a bottom margin
            className="mb-10"
          >
            支付方式
          </Typography>
          <RadioGroup
            aria-labelledby="example-payment-channel-label"
            overlay
            name="payment-channel"
            defaultValue="支付宝支付"
            onChange={(event) =>
              setPaymentType(event.target.value as PaymentType)
            }
          >
            <List
              component="div"
              variant="outlined"
              orientation="horizontal"
              sx={{
                borderRadius: "sm",
                boxShadow: "sm",
              }}
              className="bg-gray-100 p-2 rounded-lg mb-1"
            >
              {["支付宝支付", "微信支付"].map((value, index) => (
                <React.Fragment key={value}>
                  {index !== 0 && <ListDivider />}
                  <ListItem className="flex-item">
                    <Radio
                      color={"success"}
                      id={value}
                      value={value}
                      label={value}
                    />
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </RadioGroup>
        </Box>
        <div className="h-28"></div>
      </div>
      <div className="text-gray-900 bg-white w-full max-w-md p-1 fixed bottom-0">
        <div className="flex justify-between items-center p-2 mb-2">
          <div className="text-2xl">应付:</div>
          <div className="text-3xl text-orange-500">{price} 元</div>
        </div>
        <button
          className={`bg-blue-600 text-white p-2 rounded-lg w-full ${
            !selectedPlan || !selectedDuration || !email ? "bg-gray-500" : ""
          }`}
          disabled={!selectedPlan || !selectedDuration || !email}
          onClick={startPayment}
        >
          确认付款
        </button>
      </div>
    </div>
  );
}

export default RenewSubscriptionPage;
