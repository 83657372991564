import React, {useEffect, useState} from "react";
import {configuration} from "../state/configuration";
import {Alert, Button} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import NavigationIcon from "../navigationIcon.svg";

interface UserInfo {
  email: string;
  shortUrl: string;
  subscriptionInfo: string;
  expireDaysFromNow: number;
}
function OrderPage() {
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const [alert, setAlert] = useState<{ visible: boolean; message: string }>({
    visible: false,
    message: "",
  });

  const BASE_URL = `${configuration.SUB_API_URL}/api/short_url/`;
  const resetShortUrl = async () => {
    let myToken = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${configuration.SUB_API_URL}/api/user/short-url`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${myToken}`,
          },
        }
      );

      if (response.ok) {
        const newShortUrl = await response.text();
        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          shortUrl: newShortUrl,
          email: prevUserInfo?.email || "",
          subscriptionInfo: prevUserInfo?.subscriptionInfo || "",
          expireDaysFromNow: prevUserInfo?.expireDaysFromNow || 0,
        }));
        setAlert({ visible: true, message: "短链接重置成功" });
      } else {
        throw new Error("Failed to reset short URL");
      }
    } catch (error) {
      console.error("Error:", error);
      setAlert({ visible: true, message: "Failed to reset short URL" });
    }
  };

  // Fetch user information only once when the page is initialized
  useEffect(() => {
    let myToken = localStorage.getItem("token");

    fetch(`${configuration.SUB_API_URL}/api/user/info`, {
      headers: {
        Authorization: `Bearer ${myToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUserInfo(data);
        console.log(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []); // Empty dependency array

  // Set a timer to hide the alert whenever it becomes visible
  useEffect(() => {
    if (alert.visible) {
      const timer = setTimeout(() => {
        setAlert((prevAlert) => ({ ...prevAlert, visible: false }));
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alert.visible]); // Added alert.visible to the dependency array

  if (!userInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-gray-800 text-white min-h-screen">
      <div className="bg-blue-900 p-4 text-center text-2xl font-bold">
        101加速器
      </div>
      {alert.visible && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
          <Alert variant="filled" severity="success" onClose={() => {}}>
            {alert.message}
          </Alert>
        </div>
      )}
      <div className="p-4">
        <div className="bg-gray-700 p-4 mb-4">
          <div className="text-lg mb-2">我的订单</div>
          <div className="flex justify-between items-center mb-2">
            <div>订阅套餐：{userInfo.subscriptionInfo}</div>
            <Button
              component={RouterLink}
              className="text-sm text-color-custom" // Remove 'ml-auto'
              state={{ email: userInfo.email }}
              to={{
                pathname: "/renew-subscription",
              }}
              style={{
                marginLeft: "auto",
              }}
            >
              续费
              <img
                src={NavigationIcon}
                alt="navigation"
                className="inline-block ml-2"
                style={{ width: "1.8em", height: "1.8em" }}
              />
            </Button>
          </div>
          <div className="mb-2">
            过期时间：{userInfo.expireDaysFromNow}天后过期
          </div>
          <div className="mb-2">
            <div className="mb-4">订阅链接：{BASE_URL + userInfo.shortUrl}</div>
            <Button
              className="text-blue-400"
              variant="contained"
              onClick={() => {
                navigator.clipboard.writeText(BASE_URL + userInfo.shortUrl);
                setAlert({ visible: true, message: "订阅链接复制成功" });
              }}
            >
              点击复制订阅链接
            </Button>
          </div>
        </div>
        <div className="flex justify-between items-center bg-gray-700 p-4 mb-4">
          <button
            onClick={resetShortUrl}
            className="bg-blue-900 py-2 px-4 flex-1 mr-2"
          >
            重置链接
          </button>
          <button className="bg-gray-600 py-2 px-4 flex-1 ml-2">
            导入客户端
          </button>
        </div>
        <div className="bg-gray-700 p-4 text-center text-lg mb-4">
          <div className="mb-2">操作步骤</div>
          <div className="flex justify-around items-center">
            <div>
              <i className="fas fa-user-circle text-2xl"></i>
              <div>购买订阅</div>
            </div>
            <div>
              <i className="fas fa-arrow-right text-xl"></i>
            </div>
            <div>
              <i className="fas fa-arrow-down text-2xl"></i>
              <div>导入客户端</div>
            </div>
            <div>
              <i className="fas fa-arrow-right text-xl"></i>
            </div>
            <div>
              <i className="fas fa-globe text-2xl"></i>
              <div>启动代理</div>
            </div>
          </div>
        </div>
        <div className="bg-gray-700 p-4">
          <div className="text-lg mb-2">推荐客户端软件</div>
          <div className="mb-2">苹果手机 | Shadowrocket</div>
          <div className="mb-2">安卓手机 | V2ray</div>
          <div className="mb-2">安卓电脑 | ClashX</div>
          <div>PC电脑 | V2ray</div>
          <div>
            24小时客服请加群:
            <a href="https://t.me.proxyfortomo" target="_blank" rel="noopener noreferrer" className="text-blue-500">
              TG群
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderPage;
