import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function PaymentCompletePage() {
  const navigate = useNavigate();
  const [param, setParam] = useState<string | null>(null);

  useEffect(() => {
    // Extract parameters from URL
    const params = new URLSearchParams(window.location.search);
    const money = params.get("money");
    const name = params.get("name");
    const out_trade_no = params.get("out_trade_no");
    const pid = params.get("pid");
    const trade_no = params.get("trade_no");
    const trade_status = params.get("trade_status");
    const type = params.get("type");
    const sign = params.get("sign");
    const sign_type = params.get("sign_type");

    // Assign the value of 'param' from the URL parameters
    let token = params.get("param");
    // Save the param to local storage for later use if param is not null
    if (token) localStorage.setItem("token", token);
    // Set the state variable 'param' with the value of 'token'
    setParam(token);
    console.log(
      money,
      name,
      out_trade_no,
      param,
      pid,
      trade_no,
      trade_status,
      type,
      sign,
      sign_type
    );
    // if param is null, navigate to the home page in three seconds
    if (token == null) {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  }, []);
  const navigateToOrderPage = () => {
    navigate("/order", { state: { token: param } });
  };

  return (
    <div className="home bg-gray-900 text-white p-4 max-w-md mx-auto h-screen">
      <header className="text-3xl font-bold text-center mb-6">
        <div className="mb-4">101加速器</div>
      </header>

      <div className="flex items-center justify-center h-full">
        {param ? (
          <button
            onClick={navigateToOrderPage}
            className="text-white w-full h-full"
          >
            点击查询订单
          </button>
        ) : (
          <div>
            自动跳转至订单失败，即将跳转至首页，请点击首页订单按钮尝试查询订单，支付失败请联系管理员
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentCompletePage;
