import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface LoginState {
  email: string | undefined;
  password: string | undefined;
  isLoggedIn: boolean;
  token: string | null; // Add this line
}

const initialState: LoginState = {
  email: undefined,
  password: undefined,
  isLoggedIn: false,
  token: null, // And this line
};

const loginPageSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setToken: (state, action: PayloadAction<string | null>) => { // Add this reducer
      state.token = action.payload;
    },
  },
});

export default loginPageSlice.reducer;
export const { setEmail, setPassword, setLoggedIn, setToken } = loginPageSlice.actions; // Export the new action