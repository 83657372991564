import {configureStore} from '@reduxjs/toolkit';
import homeReducer from './home/HomeSlices';
import LoginReducer from './login/LoginSlice';
import {paymentSlice} from "./payment/PaymentSlice";

// Store
const store = configureStore({
  reducer: {
    home: homeReducer,
    login: LoginReducer,
    payment: paymentSlice.reducer,
  },
});


export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;