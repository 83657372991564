import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./loginPage/LoginPage";
import HomePage from "./homePage/HomePage";
import OrderPage from "./orderPage/OrderPage";
import store from "./state/store";
import { Provider } from "react-redux";
import PaymentPage from "./PaymentPage/PaymentPage";
import PaymentCompletePage from "./PaymentCompletePage/PaymentCompletePage";
import NotFoundPage from "./NotFound/LoginPage";
import RenewSubscriptionPage from "./PlanrenewPage/RenewSubscriptionPage";

const router = createBrowserRouter([
  { path: "", element: <HomePage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/order", element: <OrderPage /> },
  { path: "/payment", element: <PaymentPage /> },
  { path: "/payment-complete", element: <PaymentCompletePage /> },
  { path: "/renew-subscription", element: <RenewSubscriptionPage /> },
  { path: "*", element: <NotFoundPage /> }, // Redirect to homepage for any other path
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
