import { useNavigate } from "react-router-dom";
import React from "react";

function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-900 text-white p-4 max-w-md mx-auto h-screen">
      <h1 className="text-3xl font-bold text-center text-yellow-400 mb-4">
        101加速器
      </h1>
      <p className="text-gray-400 text-center mb-8">您访问的页面不存在</p>
      <button
        onClick={() => navigate("/")}
        className="mt-4 w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        返回首页
      </button>
    </div>
  );
}

export default NotFoundPage;
