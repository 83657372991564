import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state/store";
import {
  setEmail,
  setLoggedIn,
  setPassword,
  setToken,
} from "../state/login/LoginSlice";
import React from "react";
import { Snackbar } from "@mui/material";
import { configuration } from "../state/configuration";

function LoginPage() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  // State variable to control whether the Snackbar is open or not
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const dispatch = useDispatch();
  const email = useSelector((state: RootState) => state.login.email);
  const password = useSelector((state: RootState) => state.login.password);
  const isValidEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const handleLogin = async () => {
    try {
      const response = await fetch(`${configuration.SUB_API_URL}/api/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/hal+json", // Add this line
        },
        body: JSON.stringify({ password, email }),
      });

      if (response.ok) {
        const data = await response.json(); // Parse the JSON response
        console.log("Login successful", data.accessToken);
        dispatch(setLoggedIn(true)); // Dispatch the setLoggedIn action with true
        dispatch(setToken(data.accessToken)); // Dispatch the setToken action with the accessToken
        // Save the token to localStorage
        localStorage.setItem("token", data.accessToken);
        // Navigate to the OrderPage
        navigate("/order");
      } else {
        // handle error
        console.error("fetch error response not ok"); // Log the error message to the console
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("fetch error", error); // Log the error message to the console
      setOpenSnackbar(true);
      // Here you can handle the error, for example, show an error message to the user
      // or dispatch an action to update the Redux store with the error message
    }
  };

  return (
    <div className="bg-gray-900 text-white p-4 max-w-md mx-auto h-screen">
      <button onClick={goBack} className="top-4 left-4 text-white text-2xl">
        <i className="fas fa-arrow-left"></i>
      </button>
      <h1 className="text-3xl font-bold text-center text-white-400 mb-4">
        101加速器
      </h1>
      <p className="text-gray-400 text-center mb-8">订阅查询</p>
      <div className="mb-4">
        <input
          className="w-full p-3 border border-gray-600 bg-gray-800 text-white rounded"
          type="text"
          placeholder="邮箱"
          value={email}
          onChange={(e) => dispatch(setEmail(e.target.value))}
        />
      </div>
      <div className="mb-8">
        <input
          className="w-full p-3 border border-gray-600 bg-gray-800 text-white rounded"
          type="password"
          placeholder="密码"
          value={password}
          onChange={(e) => dispatch(setPassword(e.target.value))}
        />
      </div>
      <button
        onClick={handleLogin}
        className={`w-full text-white p-3 rounded mb-4 ${
          !email || !password || !isValidEmail(email)
            ? "bg-gray-600"
            : "bg-blue-800"
        }`}
        disabled={!email || !password || !isValidEmail(email)}
      >
        查询订阅
      </button>
      <button className="w-full bg-transparent text-blue-400 p-3 rounded border border-blue-400">
        忘记密码
      </button>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        message="Login Failed"
      />
    </div>
  );
}

export default LoginPage;
