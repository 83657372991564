import {createSlice} from "@reduxjs/toolkit";

export interface HomeState{
    selectedPlan: string | undefined;
    selectedDuration: string | undefined;
    email: string | undefined;
    password: string | undefined;
    comfirmationPassword: string | undefined;
}
export const initialState: HomeState = {
    selectedPlan: undefined,
    selectedDuration: undefined,
    email: undefined,
    password: undefined,
    comfirmationPassword: undefined,
};

const homePageSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        selectPlan: (state, action) => {
            // console.log("selectPlan-----",action.payload)
            state.selectedPlan = action.payload;
        },
        selectDuration: (state, action) => {
            // console.log("selectDuration-----",action.payload)
            state.selectedDuration = action.payload;
        },
        fillEmail: (state, action) => {
            // console.log("fillEmail-----",action.payload)
            state.email = action.payload;
        },
        fillPassword: (state, action) => {
            // console.log("fillPassword-----",action.payload)
            state.password = action.payload;
        },
        fillComfirmationPassword: (state, action) => {
            // console.log("fillComfirmationPassword-----",action.payload)
            state.comfirmationPassword = action.payload;
        }
    },
});

export default homePageSlice.reducer;
export const {selectPlan, selectDuration,fillEmail,fillPassword,fillComfirmationPassword} = homePageSlice.actions;