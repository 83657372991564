import React, {useEffect} from "react";
import './PaymentPage.css'; // Import the CSS file
import {useSelector} from 'react-redux';
import {RootState} from '../state/store';

function PaymentPage() {
    const htmlContent = useSelector((state: RootState) => state.payment.htmlContent);

    useEffect(() => {
        const container = document.getElementById('payment-container');
        if (!container) return;

        const scripts = Array.from(container.getElementsByTagName('script'));
        scripts.forEach(oldScript => {
            const newScript = document.createElement('script');
            Array.from(oldScript.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
            newScript.appendChild(document.createTextNode(oldScript.innerHTML));
            if (oldScript.parentNode) {
                oldScript.parentNode.replaceChild(newScript, oldScript);
            }
        });
    }, [htmlContent]);

    return (
        <div id="payment-container" className="home bg-gray-900 text-white p-4 max-w-md mx-auto h-screen" dangerouslySetInnerHTML={{__html: htmlContent}}/>
    );
}

export default PaymentPage;